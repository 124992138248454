import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Sponsors() {
    /* useEffect(() => {



        var sponsorsItemAni = document.querySelectorAll('.sponsors__item'),
            sponsorsItemAniTimers = [8000, 8000, 8000], counter = 0;

        window.addEventListener('load', sponsorsItemAniStart);

        var sponsorsItemAniStart = setTimeout(function () {
            sponsorsItemAniFunc();
        }, 100);

        function sponsorsItemAniFunc() {
            for (var i = 0; i < sponsorsItemAni.length; i++) {
                sponsorsItemAni[i].classList.remove('on');
            }
            sponsorsItemAni[counter].classList.add('on');
            setTimeout(sponsorsItemAniFunc, sponsorsItemAniTimers[counter]);
            counter = (counter + 1) % sponsorsItemAniTimers.length;
        }








    }, []); */


    const [theme, setTheme] = useState(
        JSON.parse(localStorage.getItem("theme")) || "dark"
    );



    const handleThemeChange = () => {
        const toggleText = document.querySelector(".toggle-text")


        if (theme === "light") {
            setTheme("dark");
            toggleText.innerHTML = "Cambiar fondo"


        } else {
            setTheme("light");
            toggleText.innerHTML = "Cambiar fondo"
        }
    };

    useEffect(() => {
        localStorage.setItem("theme", JSON.stringify(theme));
        document.body.setAttribute("data-theme", theme);
    }, [theme]);



    return (
        <>
            <div className="background sponsorspage__body">
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>
                <span className="blob"></span>

                <header className="header">
                    {/*  <Link to="login">
            <button>Login</button>
          </Link> */}
                    <Link to="/">
                        <div className="logo__container">
                            <img className="logo-main" src={theme === "dark" ? "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-light-v3.svg" : "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-dark-v3.svg"}
                                alt="FIVERSO"
                            />
                        </div>
                    </Link>

                    <div className="menu-main">
                        <button onClick={handleThemeChange} className="toggle-light" >
                            <span className="toggle-text">Cambiar fondo</span>
                            <div className="toggle-light__checkbox-label">
                                <span className="ball"></span>
                            </div>

                        </button>
                        <Link to="/info" className="menu-main__link">

                            ¿Qué es FIVERSO?
                        </Link>



                    </div>

                </header>

                <div className="sponsorspage__main">



                    <h1 className="sponsorspage__title">
                        Sponsors

                    </h1>

                    <div className="sponsorspage__cards">
                        <div className="card">
                            <img src="https://d2s405iubwy463.cloudfront.net/BME/mvl8wmxyzmm-Z9aX_zry.svg" alt="" />
                            <p>
                                Bolsas y Mercados Españoles (BME), compañía del Grupo SIX, es el operador de todos los mercados de valores y sistemas financieros de nuestro país. Desde sus inicios, el Grupo ha sido una referencia en el sector tanto en términos de solvencia, como de eficiencia y rentabilidad.
                            </p>
                            <div className="buttons">
                                <a href="https://www.fiverso.com/6323589e020139a8b6068dba" className="menu-main__link">
                                    Ir a ficha
                                </a>
                                <a href="https://www.bolsasymercados.es/esp/Home" className="menu-main__link-v2" target="_blank" rel="noreferrer">
                                    Más información
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="sponsorspage__cards">
                        <div className="card">
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/OFISO/ofiso-logo-v2.svg"
                                alt=""
                            />
                            <p>
                                El Observatorio Español de la Financiación Sostenible (OFISO) es un foro de encuentro, información y debate para compañías, entidades financieras, administraciones públicas y otros agentes de la industria financiera, a través de sus profesionales de las Finanzas Sostenibles, y para dar visibilidad y notoriedad a su compromiso con la Financiación Sostenible.
                            </p>
                            <div className="buttons">
                                <a href="https://www.fiverso.com/6324487d020139a8b6068fc4" className="menu-main__link">
                                    Ir a ficha
                                </a>
                                <a href="https://www.ofiso.es" className="menu-main__link-v2" target="_blank" rel="noreferrer">
                                    Más información
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="sponsorspage__cards">
                        <div className="card">
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/GFE/gfe-v2-logo.svg"
                                alt=""
                            />
                            <p>
                                Guía de la Financiación Empresarial es un medio digital especializado en el ámbito de la Financiación Empresarial, independiente y profesional que defiende unos valores basados en una eficiente y cada vez más perfecta relación entre el sector financiero y la empresa.
                            </p>

                            <div className="buttons">
                                <a href="https://www.fiverso.com/63235168020139a8b6068d3b" className="menu-main__link">
                                    Ir a ficha
                                </a>
                                <a href="https://www.guiafinem.com" className="menu-main__link-v2" target="_blank" rel="noreferrer">
                                    Más información
                                </a>
                            </div>
                        </div>
                    </div>

                    {/*  <div className="sponsorspage__cards">
                        <div className="card">
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-1.svg"
                                alt=""
                            />
                            <p>
                            Como Sponsor de FIVERSO tendrá una visibilidad en toda la plataforma y en las distintas páginas de este portal de financiación verde y sostenible, con información y enlaces a sus servicios y productos relacionados con las finanzas sostenibles y la sostenibilidad y los criterios ESG, determinados por el propio Sponsor, o su actividad y operativa en este terreno, así como un video seleccionado por el propio Sponsor.
                            </p>
                        </div>
                    </div>

                    <div className="sponsorspage__cards">
                        <div className="card">
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-2.svg"
                                alt=""
                            />
                            <p>
                            Como Sponsor de FIVERSO tendrá una visibilidad en toda la plataforma y en las distintas páginas de este portal de financiación verde y sostenible, con información y enlaces a sus servicios y productos relacionados con las finanzas sostenibles y la sostenibilidad y los criterios ESG, determinados por el propio Sponsor, o su actividad y operativa en este terreno, así como un video seleccionado por el propio Sponsor.                            </p>
                        </div>
                    </div> */}


                </div>


            </div >
        </>
    );
}
