import { useState, useEffect } from "react";
import Table from "../../components/Table";
import { listCategories } from "../../services/categories";
import { deleteRecord, listRecords } from "../../services/records";


export function RecordsList() {
  const [records, setRecords] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    listRecords({ limit: 10000 }).then((response) =>
      setRecords(response.elements)
    );
    listCategories({ limit: 10000 }).then((response) =>
      setCategories(response.elements)
    );
  }, []);

  const deleteRecordFromList = (element) => {
    return deleteRecord(element.id).then((element) => {
      if (element.id) {
        setRecords(records.filter((record) => record.id !== element.id));
      }
    });
  };

  const applyFilter = (e) => {
    e.preventDefault();
    listRecords({
      categories: selectedCategories,
      search: search ? search : undefined,
      limit: 10000,
    }).then((response) => setRecords(response.elements));
  };

  const onChangeCheckbox = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    }
  };

  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSearch("");
  };

  return (
    <>
     

      <form
      className="form-records-list"
        method="post"
        onSubmit={applyFilter}
        style={{ display: "inline-grid" }}
      >
        <input placeholder="Buscar" className="records-admin-search" type="text" value={search} onChange={onChangeSearch} />
        <div className="records-admin-labels">
        {categories.map((category, index) => (
          <label key={"category-label-" + index}>
            <input
              type="checkbox"
              value={category.id}
              onChange={onChangeCheckbox}
              checked={selectedCategories.includes(category.id)}
            />
            {category.name}
          </label>

        ))}
        </div>
        <div className="records-admin-btns">
        <button type="submit" className="btn-search">Buscar</button>
        <button onClick={clearFilters} className="btn-clear">Limpiar</button>
        </div>
       
      </form>

      <Table
        format={{
          companyName: "",
          email: "",
          url: "",
          categories: "",
          finalPdfUrl: "",
          logoUrl: "",
        }}
        name="Records list"
        elements={records}
        pathName="admin/records"
        deleteFunction={deleteRecordFromList}
      ></Table>

      <br />
    </>
  );
}
