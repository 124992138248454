import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/auth";

export function Login() {
  const [body, setBody] = useState({ email: "", password: "" });

  const [sending, setSending] = useState(false);

  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;
    setBody({ ...body, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setSending(true);
    login(body).then((response) => {
      if (response.error) {
        alert(response.message);
      } else {
        localStorage.setItem("user", response.accessToken);
        navigate("/admin");
      }
      setSending(false);
    });
  };

  return (
    <>
      <h1>Login</h1>
      <form onSubmit={onSubmit} method="POST">
        <input
          type="email"
          placeholder="email"
          name="email"
          value={body.email}
          onChange={onChange}
          required
        ></input>
        <input
          type="password"
          placeholder="password"
          name="password"
          value={body.password}
          onChange={onChange}
          required
        ></input>
        <button type="submit" disabled={sending}>
          Submit
        </button>
      </form>
    </>
  );
}
