import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listCategories } from "../services/categories";
import { listArticulos } from "../services/articulos";
import { listRecords } from "../services/records";
import { listNews } from "../services/news";

export default function Landing() {
  const [records, setRecords] = useState([]);

  const [news, setNews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [temporalCategories, setTemporalCategories] = useState([]);

  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(10000);

  const limit = 50,
    order = "companyName";

  const handleRecordsFetch = (e) => {
    e.preventDefault();
    setOffset(offset + limit);
  };

  const fetchRecords = () => {
    listRecords({
      limit,
      offset,
      order,
      categories: selectedCategories,
      search,
    }).then((response) => {
      setTotalCount(response.totalCount);
      setRecords(
        offset === 0 ? response.elements : [...records, ...response.elements]
      );
    });
  };

  useEffect(() => {
    
    fetchRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    setRecords([]);


    if (offset === 0) {
      fetchRecords();
    } else {
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedCategories]);

  useEffect(() => {
    listCategories({ limit: 10000 }).then((response) =>
      setCategories(response.elements)


    );
    listArticulos({ limit: 10000, order: "order" }).then((response) =>
      setArticulos(response.elements)
    );

    listNews({ limit: 10000, order: "order" }).then((response) =>
      setNews(response.elements)
    );
  }, []);

  const onChangeCheckbox = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setTemporalCategories([...temporalCategories, value]);
    } else {
      e.target.parentElement.classList.remove("active");
      setTemporalCategories(
        temporalCategories.filter((category) => category !== value)
      );
    }
  };

  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);


  };

  const clearFilters = (e) => {
    setSelectedCategories([]);
    setTemporalCategories([]);
    setSearch("");
  };
  /* 
    let cube = document.querySelector(".sponsors__container");
    let pos = 0;
    let sponsorBall = document.querySelectorAll(".sponsors__container .conatiner > div");
    console.log(sponsorBall[0]);
    let sponsorCount = 0;
  
    let nextImage = function () {
      pos -= 90;
      cube.style.transform = `rotateY(${pos}deg)`;
    };
  
    console.log(sponsorCount);
  
    let timer = () => {
      setInterval(function () {
        nextImage();
      }, 5000);
    };
  
    timer();
  
    let sponsorsWrapper = document.querySelector(".sponsors__wrapper");
  
    setInterval(function () {
      sponsorsWrapper.classList.add("active");
  
      setTimeout(() => {
        sponsorsWrapper.classList.remove("active");
      }, 3000);
  
      if (sponsorCount < 3) {
        sponsorCount += 1;
        sponsorBall.forEach((element) => {
          element.classList.remove("active");
        });
  
        sponsorBall[sponsorCount].classList.add("active");
      } else {
        sponsorCount = 0;
  
        sponsorBall.forEach((element) => {
          element.classList.remove("active");
        });
        sponsorBall[sponsorCount].classList.add("active");
      }
      console.log(sponsorCount);
    }, 5000);


  var sponsorsItemAni = document.querySelectorAll('.sponsors__item'),
    sponsorsItemAniTimers = [8000, 8000, 8000], counter = 0;

  window.addEventListener('load', sponsorsItemAniStart);

  var sponsorsItemAniStart = setTimeout(function () {
    sponsorsItemAniFunc();
  }, 100);

  function sponsorsItemAniFunc() {
    for (var i = 0; i < sponsorsItemAni.length; i++) {


      sponsorsItemAni[i].classList.remove('on');


    }
    sponsorsItemAni[counter].classList.add('on');
    setTimeout(sponsorsItemAniFunc, sponsorsItemAniTimers[counter]);
    counter = (counter + 1) % sponsorsItemAniTimers.length;
  }



   */



  //const menuMain = document.querySelector(".menu-main")
  const articlesTab = document.querySelector(".articles--tab")
  const newsTab = document.querySelector(".news--tab")
  const asideArticles = document.querySelector(".aside__articles")
  const asideNews = document.querySelector(".aside__news")
  const filtersButton = document.querySelector(".filters-button")
  const filtersSection = document.querySelector(".filters-section")
  const cardsSearch = document.querySelector(".cards__search")
  const filterbtnSearch = document.querySelector(".btn--filters-search")
  const articlesButton = document.querySelector(".articles-button")
  const articlesSection = document.querySelector(".home__aside")
  const articlesCloseButton = document.querySelector(".articles-close")
  const filtersCloseButton = document.querySelector(".filters-close")


  /* 
    if (menuMain) {
      const checkboxToggle = document.getElementById("checkboxToggleLight")
  
      //check storage if dark mode was on or off
      if (sessionStorage.getItem("mode") == "light") {
        lightmode(); //if dark mode was on, run this funtion
      } else {
        nolight(); //else run this funtion
      }
  
  
      //function for checkbox when checkbox is checked
      function lightmode() {
        document.body.classList.add("light"); //add a class to the body tag
        checkboxToggle.checked = true; //set checkbox to be checked state
        sessionStorage.setItem("mode", "light"); //store a name & value to know that dark mode is on
      }
  
      //function for checkbox when checkbox is not checked
      function nolight() {
        document.body.classList.remove("light"); //remove added class from body tag
        checkboxToggle.checked = false; //set checkbox to be unchecked state
        sessionStorage.setItem("mode", "light"); //store a name & value to know that dark mode is off or light mode is on
      }
  
  
      checkboxToggle.addEventListener("click", () => {
        let image = document.querySelector(".logo-main")
  
        if (document.getElementById('checkboxToggleLight').checked) {
          lightmode();
          document.body.classList.add("light");
          image.src = "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-dark-v3.svg";
  
        } else {
          nolight();
          document.body.classList.remove("light");
          image.src = "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-light-v3.svg";
  
        }
      })
    } */



  const [theme, setTheme] = useState(
    JSON.parse(localStorage.getItem("theme")) || "dark"
  );



  const handleThemeChange = () => {
    const toggleText = document.querySelector(".toggle-text")


    if (theme === "light") {
      setTheme("dark");
      toggleText.innerHTML = "Cambiar fondo"


    } else {
      setTheme("light");
      toggleText.innerHTML = "Cambiar fondo"
    }
  };

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
    document.body.setAttribute("data-theme", theme);
  }, [theme]);


  if (articlesTab) {
    articlesTab.addEventListener("click", (e) => {
      newsTab.classList.remove("active");
      e.currentTarget.classList.add("active");
      asideNews.style.display = "none";
      asideArticles.style.display = "block";
    });
  }

  if (newsTab) {
    newsTab.addEventListener("click", (e) => {
      articlesTab.classList.remove("active");
      e.currentTarget.classList.add("active");
      asideArticles.style.display = "none";
      asideNews.style.display = "block";
    });
  }

  if (cardsSearch) {
    filtersButton.addEventListener("click", (e) => {
      filtersSection.classList.add("active");
    });

    articlesButton.addEventListener("click", (e) => {
      articlesSection.classList.add("active");
    });

  }
  if (articlesSection) {
    articlesCloseButton.addEventListener("click", (e) => {
      articlesSection.classList.remove("active");
    });

  }


  if (filtersSection) {
    filterbtnSearch.addEventListener("click", (e) => {
      filtersSection.classList.remove("active");
    });
    filtersCloseButton.addEventListener("click", (e) => {
      filtersSection.classList.remove("active");
    });



  }

  return (
    <>
      <div className="background home">
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>
        <span className="blob"></span>

        <header className="header">
          {/*  <Link to="login">
            <button>Login</button>
          </Link> */}
          <Link to="/" className="logo__link">
            <div className="logo__container">
              <img className="logo-main" src={theme === "dark" ? "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-light-v3.svg" : "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-dark-v3.svg"}
                alt="FIVERSO"
              />
            </div>
          </Link>

          <div className="menu-main">
            <button onClick={handleThemeChange} className="toggle-light" >
              <span className="toggle-text">Cambiar fondo</span>
              <div className="toggle-light__checkbox-label">
                <span className="ball"></span>
              </div>

            </button>
            <Link to="/info" className="menu-main__link">

              ¿Qué es FIVERSO?
            </Link>



          </div>
          <div className="sponsors__container">
            <h2 className="sponsors__title">
              Sponsors
            </h2>


            <Link to="/sponsors" className="container">
              <img
                src="https://d2s405iubwy463.cloudfront.net/BME/mvl8wmxyzmm-Z9aX_zry.svg"
                alt=""
              />
              <img
                src="https://d2s405iubwy463.cloudfront.net/OFISO/ofiso-logo-v2.svg"
                alt=""
              />
              <img
                src="https://d2s405iubwy463.cloudfront.net/GFE/gfe-v2-logo.svg"
                alt=""
              />
             {/*  <img
                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-1.svg"
                alt=""
              />
              <img
                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-2.svg"
                alt=""
              /> */}
            </Link>


          </div>
        </header>

        <main className="home__main">
          <div className="home__filters filters-section">
            <p className="filters__title">Categorias</p>
            <div className="filters-close">
              <div></div>
              <div></div>

            </div>

            <form
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                setSelectedCategories(temporalCategories);
              }}
              style={{ display: "inline-grid" }}
            >
              <div className="btn--filters-all-container">
                <button
                  className={`btn--filters-all ${temporalCategories.length ? "" : "active"
                    }`}
                  onClick={clearFilters}
                >
                  <img
                    src="https://d2s405iubwy463.cloudfront.net/categories/icon-categorie-todos.svg"
                    alt=""
                  />
                  Todas
                </button>
              </div>

              <ul className="filters__list">
                {categories.map((category, index) => {
                  const isChecked = temporalCategories.includes(category.id);
                  console.log(category.name)
                  return (
                    <li className="filters__item">
                      <label
                        className={`filters__label ${isChecked ? "active" : ""
                          }`}
                        key={"category-label-" + index}
                      >
                        <input
                          type="checkbox"
                          value={category.id}
                          onChange={onChangeCheckbox}
                          checked={isChecked}
                          key={"category-input-" + index}
                        />
                        <img
                          src={
                         
                            "https://d2s405iubwy463.cloudfront.net/categories/icon-categorie-" + category.name
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .replace("/[^a-zA-Z0-9 ]/g", "")
                              .replaceAll("/", "")
                              .replaceAll(" ", "-") +  ".svg"
                          }
                  
                          alt=""
                        />

                        {category.name}
                      </label>
                    </li>
                  );
                })}
              </ul>
              <div className="btn--filters-container">
                <button className="btn--filters-search" type="submit">
                  Filtrar
                </button>
              </div>
            </form>
          </div>
          <div className="home__cards">

            <div className="cards__search">
              <div className="articles-button">

                <div></div>
                <div></div>
                <div></div>

              </div>
              <img src="" alt="" />
              <input
                placeholder="Buscar"
                type="text"
                value={search}
                onChange={onChangeSearch}
              />
              <div className="filter filters-button">

                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.999 17.998C23.6559 17.998 24.999 16.6549 24.999 14.998C24.999 13.3412 23.6559 11.998 21.999 11.998C20.3422 11.998 18.999 13.3412 18.999 14.998C18.999 16.6549 20.3422 17.998 21.999 17.998Z" fill="#0CA1D5" />
                  <path d="M35.999 13.998C38.2082 13.998 39.999 12.2072 39.999 9.99805C39.999 7.78891 38.2082 5.99805 35.999 5.99805C33.7899 5.99805 31.999 7.78891 31.999 9.99805C31.999 12.2072 33.7899 13.998 35.999 13.998Z" fill="#0CA1D5" />
                  <path d="M47.003 21H16.996C15.344 21 14 22.344 14 23.995V25V25.998V27.259C14 27.976 14.257 28.669 14.722 29.209L25.278 41.524C25.743 42.068 26 42.763 26 43.479V50.443C26 51.095 26.32 51.707 26.854 52.077L34.87 57.646C35.211 57.882 35.607 58.002 36.006 58.002C36.322 58.002 36.64 57.926 36.932 57.773C37.591 57.428 38 56.751 38 56.007V43.479C38 42.763 38.257 42.07 38.722 41.526L49.277 29.21C49.743 28.668 50 27.975 50 27.259V26.001V25V23.995C50 22.344 48.655 21 47.003 21ZM37.204 40.225C36.757 40.746 36.442 41.354 36.241 42H33V44H36L36.001 46H33V48H36.003L36.005 50H34V52H36.007L36.01 56.002L28 50.442V43.478C28 42.285 27.572 41.128 26.795 40.223L17.176 29H46.824L37.204 40.225ZM48 26.001C48 26.552 47.552 27 47 27H17.002C16.449 27 16 26.551 16 25.998V25V23.995C16 23.446 16.447 23 16.996 23H47.003C47.553 23 48 23.446 48 23.995V25V26.001Z" fill="#0CA1D5" />
                  <path d="M44.999 21C44.999 19 43.656 18 41.999 18C40.342 18 38.999 19 38.999 21H44.999Z" fill="#0CA1D5" />
                </svg>

              </div>
            </div>
            <div className="cards__list appear">
              {records.map((record, i) => {
                return (
                  <div
                    data-sponsor={record.class === "sponsor" ? "sponsor" : ""}
                    data-destacado={record.class === "destacado" ? "destacado" : ""}
                    className={record.logoUrl ? "card special" : "card"}
                    key={"record-" + i}
                  
                    
                    onClick={(event) =>{
                      if(record.class === "destacado"){
                        window.open(record.url, '_blank'); 
                      } else if(record.logoUrl){
                        window.location.href = "/" + record.id
                        
                        
                      }
                    }}
                  >
                    <div className="card__content">
                      <ul className="card__categorie--list">
                        {record.categories.map((element, i) => {
                          return (
                            <li
                              className="card__categorie tooltip-bottom"
                              key={"card-categorie-" + i}
                              data-tooltip={element.name}
                            >
                              <img
                                src={
                         
                                  "https://d2s405iubwy463.cloudfront.net/categories/icon-categorie-" + element.name
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                                    .replace("/[^a-zA-Z0-9 ]/g", "")
                                    .replaceAll("/", "")
                                    .replaceAll(" ", "-") +  ".svg?v=3"
                                }
                                alt=""
                              />
                            </li>
                          );
                        })}
                      </ul>
                      {/*   {record.class === "sponsor" ? (
                          <p className="card__sponsor">Patrocinador</p>
                        ) : (
                          ""
                        )} */}

                      {record.logoUrl ? (
                        <img alt="logo" className="card__img" src={record.logoUrl}></img>
                      ) : (
                        <h1 className="card__name" key={"card-name-" + i}>
                          {record.companyName}
                        </h1>
                      )}

                      {record.logoUrl ? (
                        <p className="card__link">+ información</p>
                      ) : (
                        ""
                      )}
                    </div>

                    {record.class === "sponsor" ? (
                      <div className="sponsor__text-container">
                        <div className="sponsor__title">Patrocinador</div>
                        <div className="sponsor__text">
                          {record.text_card_home}

                        </div>
                        <button className="sponsor__btn">
                          {" "}
                          Más información{" "}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
            {totalCount <= records.length ? (
              ""
            ) : (
              <button className="btn-more" onClick={handleRecordsFetch}>Ver más</button>
            )}
          </div>
          <aside className="home__aside">
            <div className="articles-close">


              <div></div>
              <div></div>
            </div>
            <ul className="aside__tabs">
              <li className="aside__tab articles--tab active">
                <h2 className="aside__title">Lo más destacado</h2>
              </li>
              <li className="aside__tab news--tab">
                <h2 className="aside__title">Últimas noticias</h2>
              </li>
            </ul>

            <div className="aside__articles">
              {articulos.map((articulo, i) => {
                return (
                  <div key={"articulo-" + i}>
                    <p>
                      <a href={articulo.url} target="_blank" rel="noreferrer">
                        <img
                          className="aside__img"
                          src="https://d2s405iubwy463.cloudfront.net/articulos/icon-read.svg"
                          alt=""
                        />
                        <span className="aside__text">{articulo.title}</span>
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="aside__news">
              {news.map((newsItem, i) => {
                return (
                  <div key={"newsItem-" + i}>
                    <p>
                      <a href={newsItem.url} target="_blank" rel="noreferrer">
                        <img
                          className="aside__img"
                          src="https://d2s405iubwy463.cloudfront.net/articulos/icon-read.svg"
                          alt=""
                        />
                        <span className="aside__text">{newsItem.title}</span>
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </aside>
        </main>
      </div>
    </>
  );
}
