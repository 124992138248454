import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateToken } from "../utils";

export const AuthProvider = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      const isValidToken = validateToken();
      if (!isValidToken) navigate("/");
    }
  });

  return null;
};
