import { Link, useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <h1>I'm a dashboard</h1>
      <button onClick={onLogout}>Logout</button>
      <Link to="categories">
        <button>Categories</button>
      </Link>
      <Link to="records">
        <button>Records</button>
      </Link>
      <Link to="articulos">
        <button>Articulos</button>
      </Link>
      <Link to="news">
        <button>News</button>
      </Link>
    </>
  );
}
