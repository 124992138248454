import { useState } from "react";

export default function ChangeOrderTD({
  changeOrderFunction,
  currentValue,
  currentElement,
  resetOrderFunction,
}) {
  const [order, setOrder] = useState(currentValue);

  return (
    <td>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (order !== 0 && !order) {
            return alert("Order required");
          }

          changeOrderFunction(currentElement, order);
        }}
      >
        <input
          type="number"
          defaultValue={currentValue}
          onChange={(e) => setOrder(parseInt(e.target.value))}
        />
      </form>
      {currentElement.fixed_order ? (
        <button onClick={() => resetOrderFunction(currentElement.id)}>
          Reset order
        </button>
      ) : (
        ""
      )}
    </td>
  );
}
