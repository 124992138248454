const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function fetchApi(method, path, body) {
  return fetch(`${BASE_URL}${path}`, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : undefined,
  }).then((response) => response.json());
}

export async function fetchAuthApi(method, path, body) {
  const token = localStorage.getItem("user");

  return fetch(`${BASE_URL}${path}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body ? JSON.stringify(body) : undefined,
  })
    .then((response) => response.json())
    .then((finalResponse) => {
      if (finalResponse.statusCode === 401) {
        localStorage.clear();
        return (window.location = "/login");
      }

      return finalResponse;
    });
}

export async function fetchAuthApiFormData(method, path, body) {
  const token = localStorage.getItem("user");

  return fetch(`${BASE_URL}${path}`, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: body ? body : undefined,
  })
    .then((response) => response.json())
    .then((finalResponse) => {
      if (finalResponse.statusCode === 401) {
        localStorage.clear();
        return (window.location = "/login");
      }

      return finalResponse;
    });
}
