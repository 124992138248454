import { buildParams } from "../utils";
import { fetchApi, fetchAuthApi } from "./fetchApi";

export function listRecords(query) {
  return fetchApi("GET", "/records?" + buildParams(query));
}

export function getRecordById(id) {
  return fetchApi("GET", `/records/${id}`);
}

export function createRecord(body) {
  return fetchAuthApi("POST", `/records`, body);
}

export function updateRecord(id, body) {
  return fetchAuthApi("PUT", `/records/${id}`, body);
}

export function deleteRecord(id) {
  return fetchAuthApi("DELETE", `/records/${id}`);
}
