import { useState, useEffect } from "react";
import { deleteCategory, listCategories } from "../../services/categories";
import Table from "../../components/Table";

export function CategoriesList() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    listCategories({ limit: 10000, order: "name" }).then((response) =>
      setCategories(response.elements)
    );
  }, []);

  const deleteCategoryFromList = (element) => {
    return deleteCategory(element.id).then((element) => {
      if (element.id) {
        setCategories(
          categories.filter((category) => category.id !== element.id)
        );
      }
    });
  };

  return (
    <>
      <Table
        format={{
          order: "",
          name: "",
          icon_url: "",
        }}
        name="Categories list"
        elements={categories}
        pathName="admin/categories"
        deleteFunction={deleteCategoryFromList}
      ></Table>
    </>
  );
}
