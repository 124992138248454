import { Link } from "react-router-dom";

export default function Info() {
   

    return (
        <>
            <div className="infopage__body">
                <header className="infopage__header header">

                    <Link to="/" className="logo__container">
                        <img className="logo-main" src="https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-dark-v3.svg" alt="FIVERSO" />
                    </Link>
                    <div className="menu-main">
                        <a href="#que-es" className="menu-main__link">
                            ¿FIVERSO?
                        </a>
                        <a href="#que-encontrar" className="menu-main__link">
                            ¿Qué Encontrará en FIVERSO?
                        </a>
                        <a href="#presencia" className="menu-main__link">
                            Presencia en FIVERSO
                        </a>
                        <a href="#contacto" className="menu-main__link">
                            Contacto
                        </a>


                    </div>
                    <div className="sponsors__container">
                        <h2 className="sponsors__title">
                            Sponsors
                        </h2>


                        <Link to="/sponsors" className="container">
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/BME/mvl8wmxyzmm-Z9aX_zry.svg"
                                alt=""
                            />
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/OFISO/ofiso-logo-v2.svg"
                                alt=""
                            />
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/GFE/gfe-v2-logo.svg"
                                alt=""
                            />
                           {/*  <img
                                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-1.svg"
                                alt=""
                            />
                            <img
                                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-2.svg"
                                alt=""
                            /> */}
                        </Link>


                    </div>

                </header >

                <div className="infopage__hero">
                    <div className="container">
                        <p className="text">

                            Descubra el universo
                            sobre Finanzas
                            verdes y sostenibles
                        </p>

                        <button className="btn">
                            Más información
                            <img src="https://d2s405iubwy463.cloudfront.net/files/arrow-down.svg" alt="" />
                        </button>

                    </div>
                </div>

                <div id="que-es" className="infopage__what">
                    <div className="container">
                        <p className="title">
                            ¿Qué es FIVERSO?
                        </p>

                        <ul className="list">
                            <li className="list__item">
                                FIVERSO se configura como una puerta de acceso al universo de la Financiación Sostenible.
                            </li>
                            <li className="list__item">
                                Un concentrador de todos los operadores y agentes que participan en el sector de las finanzas verdes y sostenibles
                            </li>
                            <li className="list__item">
                                Una plataforma para mantenerse informado y al día en lo referente a finanzas verdes y sostenibles.
                            </li>
                        </ul>

                    </div>
                </div>

                <div id="que-encontrar" className="infopage__find">
                    <div className="container">
                        <h2 className="title">
                            ¿Qué encontrará en FIVERSO?
                        </h2>
                        <p className="text">
                            En FIVERSO encontrará las compañías y entidades que lideran la Financiación Sostenible con sus características y sus propuestas.


                        </p>
                        <p className="text">
                            Dispondrá de artículos e informaciones sobre los aspectos fundamentales de la Financiación Sostenible, las cifras y volúmenes de este mercado.

                        </p>



                    </div>
                </div>

                <div id="por-que-estar-en-fiverso" className="infopage__why">
                    <div className="container">
                        <h2 className="title">
                            Por qué estar en FIVERSO y qué es lo que le aporta
                        </h2>
                        <p className="text">
                            Como operador en el campo de la Financiación Sostenible, FIVERSO le ayuda a destacar y sobresalir y le acerca directamente sus potenciales clientes gracias a la amplia difusión que hacemos entre quienes tienen interés por este tipo de financiación a través de distintos canales (publicidad en medios, redes sociales y acciones de marketing directo), para que busquen en FIVERSO a los líderes que pueden ayudarles en función de sus necesidades.                        </p>
                        <p className="text">
                            La amplitud de áreas operativas que presenta FIVERSO es un importante reclamo y aliciente para los interesados en conocer mejor esta operativa.
                        </p>
                        <p className="text">
                            FIVERSO contiene además una gran variedad de información, artículos, noticias y opiniones que enriquecen su oferta de cara a los usuarios.
                        </p>
                    </div>
                </div>

                <div id="presencia" className="infopage__presence">
                    <div className="container">
                        <h2 className="title">
                            Además
                        </h2>
                        <p className="text">
                            Conocemos sus esfuerzos por hacer más sostenible su compañía y por contribuir en la lucha contra el cambio climático y los ODS y la financiación sostenible de los proyectos.
                        </p>
                        <p className="text">
                            Desde FIVERSO queremos ayudarle a impulsar y potenciar sus esfuerzos y dar a conocer las compañías, entidades financieras y todo tipo de instituciones y operadores más destacados en este terreno.
                        </p>

                        <p className="text">
                            Podrá aportar información y referencias acerca de sus servicios, iniciativas, realidades y vías de contacto.
                        </p>

                        <p className="text">
                            Estar en FIVERSO le permite destacar y tener más visibilidad ante quienes se interesan por la financiación sostenible.
                        </p>

                    </div>
                </div>

                <div id="por-que-estar-en-fiverso" className="infopage__how">
                    <div className="container">
                        <h2 className="title">
                            ¿Cómo puedo estar presente en esta plataforma?
                        </h2>
                        <p className="text">
                            FIVERSO incluye todas aquellas compañías, entidades financieras, abogados, asesores, agencias o cualquier operador del ámbito de la Financiación Sostenible que consideramos que cumplen con nuestros requisitos de solvencia y experiencia. Incluye también a aquellos que cumplen estos requisitos tras analizar su solicitud.
                        </p>
                        <p className="text">
                            También puede mejorar su presencia y su perfil en FIVERSO si elige la opción Premium mediante la que aparecerán de una forma gráfica más destacada, con su logo, añadiendo información adicional con enlaces a sus áreas de especialización o productos, y las referencias de contacto que deseen aportar.                        </p>
                        <p className="text">
                            La opción Sponsor añade al perfil anterior una aparición muy destacada en la cabecera de la plataforma, de forma que todos los usuarios que entren en FIVERSO van a encontrarse con su presencia, con un perfil de liderazgo, una mayor notoriedad en la categoría que desea aparecer, y con la inclusión de información más detallada y un video aportado por el Sponsor.
                        </p>
                    </div>
                </div>

                <div className="infopage__presence-option">
                    <div className="container">
                        <h2 className="title">
                            Presencia en FIVERSO
                            <strong>Opciones</strong>
                        </h2>
                        <ul className="option__list">
                            <li className="option__item">
                                <h2>
                                    Básico
                                </h2>
                                <ul className="option__sublist">
                                    <li className="option__subitem">
                                        Nombre de empresa o entidad
                                    </li>
                                    <li className="option__subitem">
                                        Categoría
                                    </li>
                                    <li className="option__subitem">
                                        Gratuito
                                    </li>
                                </ul>
                            </li>
                            <li className="option__item">
                                <h2>
                                    Destacado
                                </h2>
                                <ul className="option__sublist">
                                    <li className="option__subitem">
                                        Logo de empresa o entidad
                                    </li>
                                    <li className="option__subitem">
                                        Categoría
                                    </li>
                                    <li className="option__subitem">
                                        Diseño destacado
                                    </li>
                                  
                                    <li className="option__subitem">
                                        Presencia de la empresa o entidad en "Últimas noticias"
                                    </li>
                                    <li className="option__subitem">
                                        €480 + IVA <small>
                                            (Anual)
                                        </small>
                                    </li>

                                </ul>

                            </li>
                            <li className="option__item">
                                <h2>
                                    Premium
                                </h2>
                                <ul className="option__sublist">
                                    <li className="option__subitem">
                                        Logo de empresa o entidad
                                    </li>
                                    <li className="option__subitem">
                                        Categoría
                                    </li>
                                    <li className="option__subitem">
                                        Diseño premium
                                    </li>
                                    <li className="option__subitem">
                                        Botón más información
                                    </li>
                                    <li className="option__subitem">
                                        Link a ficha descriptiva
                                    </li>

                                    <li className="option__subitem">
                                        Información de la empresa o entidad (Referencias, Contacto, Servicios...)
                                    </li>
                                    <li className="option__subitem">
                                        Presencia de la empresa o entidad en "Últimas noticias"
                                    </li>
                                    <li className="option__subitem">
                                        €780 + IVA  <small>
                                            (Anual)
                                        </small>
                                    </li>

                                </ul>

                            </li>
                            <li className="option__item">
                                <h2>
                                    Patrocinador principal
                                </h2>
                                <ul className="option__sublist">
                                    <li className="option__subitem">
                                        Logo de empresa o entidad
                                    </li>
                                    <li className="option__subitem">
                                        Categoría
                                    </li>
                                    <li className="option__subitem">
                                        Diseño destacado
                                    </li>

                                    <li className="option__subitem">
                                        Texto descriptivo
                                    </li>
                                    <li className="option__subitem">
                                        Botón más información
                                    </li>
                                    <li className="option__subitem">
                                        Link a ficha descriptiva
                                    </li>
                                    <li className="option__subitem">
                                        Texto dedicado en home
                                    </li>
                                    <li className="option__subitem">
                                        Información de la empresa o entidad (Textos promocionales, Referencias, Contacto, Servicios...)
                                    </li>
                                    <li className="option__subitem">
                                        Vídeo
                                    </li>
                                    <li className="option__subitem">
                                        Presencia y referencias a notas de prensa de la empresa o entidad en "Últimas noticias"
                                    </li>
                                    <li className="option__subitem">
                                        Presencia de la marca en Home y todas las páginas con espacio y logo dedicado
                                    </li>
                                    <li className="option__subitem">
                                        €3.400 + IVA <small>
                                            (Anual)
                                        </small>
                                    </li>






                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="infopage__separator-text">
                    <div className="container">

                        <p className="text">
                            FIVERSO centra su <strong> foco en el mercado de Financiación verde y sostenible en España</strong> y por ello incluye también compañías, entidades y operadores extranjeros que tienen sede y actividad en este país.

                        </p>





                    </div>
                </div>

                <div id="contacto" className="infopage__contact">

                    <h2 className="title">
                        Contacto
                    </h2>

                    <div className="container">
                        <p className="text">

                            Póngase en contacto con nosotros y escríbanos a
                        </p>

                        <a href="mailto:info@fiverso.com" className="btn">
                            info@fiverso.com
                        </a>

                    </div>
                </div>



            </div >
        </>
    );
}
