import { buildParams } from "../utils";
import { fetchApi, fetchAuthApi } from "./fetchApi";

export function listArticulos(query) {
  return fetchApi("GET", "/featured-links?" + buildParams(query));
}

export function getArticuloById(id) {
  return fetchAuthApi("GET", `/featured-links/${id}`);
}

export function createArticulo(body) {
  return fetchAuthApi("POST", `/featured-links`, body);
}

export function updateArticulo(id, body) {
  return fetchAuthApi("PUT", `/featured-links/${id}`, body);
}

export function deleteArticulo(id) {
  return fetchAuthApi("DELETE", `/featured-links/${id}`);
}

export function changeArticuloOrder(id, order) {
  return fetchAuthApi("PATCH", `/featured-links/${id}/change-order`, { order });
}

export function resetArticulosOrder() {
  return fetchAuthApi("POST", `/featured-links/reset-order`);
}

export function resetArticulosOrderById(id) {
  return fetchAuthApi("PATCH", `/featured-links/${id}/reset-order`);
}
