import { formatHeader } from "../utils";
import Select from "react-select";

export default function InputValidated({
  type,
  name,
  value,
  options,
  error,
  cb,
}) {
  const id = "f" + name;
  let selectOptions =
    options && type == "multiselect"
      ? options.map((option) => ({
          value: option.id,
          label: option.name,
        }))
      : options;

  const onChange = (e) => {
    if (type == "multiselect") {
      cb({ [name]: e.map((option) => option.value) });
    } else {
      cb({ [name]: e.target.value || null });
    }
  };
  return (
    <>
      <div className={"input-group" + (error ? " error" : "")}>
        {type === "hidden" ? (
          ""
        ) : (
          <label htmlFor={id}>{formatHeader(name)}:</label>
        )}
        {(() => {
          switch (type) {
            case "select":
              return (
                <select id={id} name={name} value={value} onChange={onChange}>
                  <option value="">Select</option>
                  {selectOptions.map((option, i) => (
                    <option value={option.id} key={"option-" + i}>
                      {option.name}
                    </option>
                  ))}
                </select>
              );

            case "multiselect":
              return (
                <>
                  <Select
                    onChange={onChange}
                    isMulti
                    options={selectOptions}
                    value={selectOptions.filter((option) =>
                      value.includes(option.value)
                    )}
                  />
                </>
              );

            default:
              return (
                <input
                  id={id}
                  type={type || "text"}
                  name={name}
                  value={value || ""}
                  onChange={onChange}
                />
              );
          }
        })()}

        <div className="error-message">{error}</div>
      </div>
    </>
  );
}
