import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createNew, getNewById, updateNew } from "../../services";
import { uploadFile } from "../../services/files";
import InputValidated from "../../components/InputValidated";
import { useFirstRender } from "../../hooks/useFirstRender";

export function NewsForm() {
  const [_new, setNew] = useState({ title: "" });
  const firstRender = useFirstRender();
  const [selectedIcon, setSelectedIcon] = useState();
  const [uploadedIcon, setUploadedIcon] = useState(false);

  const onFileChange = (e) => {
    setSelectedIcon(e.target.files[0]);
    setUploadedIcon(false);
  };

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      getNewById(id).then((response) => {
        setNew(response);
      });
    }
  }, [id]);

  useEffect(() => {
    if (!firstRender && uploadedIcon) {
      sendNewRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender, uploadedIcon]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedIcon && !uploadedIcon) {
      const formData = new FormData();

      formData.append("file", selectedIcon);
      formData.append("folder", "news");

      uploadFile(formData).then((response) => {
        if (response.error) {
          setErrors(response.message);
        } else {
          setNew({
            ..._new,
            icon_key: response.key,
          });
          setUploadedIcon(true);
        }
      });
      return;
    }
    sendNewRequest();
  };

  const sendNewRequest = () => {
    if (id) {
      return updateNew(id, _new)
        .then((response) => {
          if (response.error) {
            setErrors(response.message);
          } else {
            navigate("/admin/news", { replace: true });
          }
        })
        .catch((error) => console.log(error.message));
    } else {
      return createNew(_new)
        .then((response) => {
          if (response.error) {
            setErrors(response.message);
          } else {
            navigate("/admin/news", { replace: true });
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const onChange = (field) => {
    setNew({
      ..._new,
      ...field,
    });
  };

  return (
    <>
      <h1>News form</h1>
      <Link to="/admin/news">
        <button>Go back</button>
      </Link>
      <div className="form-div">
        <form onSubmit={handleSubmit}>
          <InputValidated
            name="title"
            value={_new.title || ""}
            error={errors.title}
            cb={onChange}
          ></InputValidated>
          <InputValidated
            name="url"
            value={_new.url || ""}
            error={errors.url}
            cb={onChange}
          ></InputValidated>
          <label htmlFor="icon">Icon:</label>
          <input type="file" name="icon" id="icon" onChange={onFileChange} />

          {_new.icon_url ? <p>Current File: {_new.icon_url}</p> : ""}
          <button>Confirmar</button>
        </form>
      </div>
    </>
  );
}
