import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listRecords } from "../../services/records";


export function RecordsDetail() {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    listRecords({ limit: 10000 }).then((response) => setRecords(response.elements));
  }, []);

  /* var sponsorsItemAni = document.querySelectorAll('.sponsors__item'),
    sponsorsItemAniTimers = [8000, 8000, 8000], counter = 0;

  window.addEventListener('load', sponsorsItemAniStart);

  var sponsorsItemAniStart = setTimeout(function () {
    sponsorsItemAniFunc();
  }, 100);

  function sponsorsItemAniFunc() {
    for (var i = 0; i < sponsorsItemAni.length; i++) {
      sponsorsItemAni[i].classList.remove('on');
    }
    sponsorsItemAni[counter].classList.add('on');
    setTimeout(sponsorsItemAniFunc, sponsorsItemAniTimers[counter]);
    counter = (counter + 1) % sponsorsItemAniTimers.length;
  } */


  const [theme, setTheme] = useState(
    JSON.parse(localStorage.getItem("theme")) || "light"
  );

  const handleThemeChange = () => {
    const toggleText = document.querySelector(".toggle-text")


    if (theme === "light") {
      setTheme("dark");
      toggleText.innerHTML = "Cambiar fondo"


    } else {
      setTheme("light");
      toggleText.innerHTML = "Cambiar fondo"
    }
  };

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
    document.body.setAttribute("data-theme", theme);
  }, [theme]);




  return (
    <>

      <header className="header">
        {/*  <Link to="login">
            <button>Login</button>
          </Link> */}
        <Link to="/" className="logo__link">
          <div className="logo__container">
            <img className="logo-main" src={theme === "dark" ? "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-light-v3.svg" : "https://d2s405iubwy463.cloudfront.net/files/logo-FIVERSO-dark-v3.svg"}
              alt="FIVERSO"
            />
          </div>
        </Link>
        <div className="menu-main">
          <button onClick={handleThemeChange} className="toggle-light" >
            <span className="toggle-text">Cambiar fondo</span>
            <div className="toggle-light__checkbox-label">
              <span className="ball"></span>
            </div>

          </button>
          <Link to="/info" className="menu-main__link">
            ¿Qué es FIVERSO?
          </Link>

        </div>
        <div className="sponsors__container">
          <h2 className="sponsors__title">
            Sponsors
          </h2>


          <Link to="/sponsors" className="container">
            <img
              src="https://d2s405iubwy463.cloudfront.net/BME/mvl8wmxyzmm-Z9aX_zry.svg"
              alt="logo"
            />
            <img
              src="https://d2s405iubwy463.cloudfront.net/OFISO/ofiso-logo-v2.svg"
              alt="logo"
            />
            <img
              src="https://d2s405iubwy463.cloudfront.net/GFE/gfe-v2-logo.svg"
              alt="logo"
            />
            {/*  <img
                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-1.svg"
                alt=""
              />
              <img
                src="https://d2s405iubwy463.cloudfront.net/LOGOIPSUM/logoipsum-2.svg"
                alt=""
              /> */}
          </Link>


        </div>
      </header>

      <main className="main--detail">


        {records.map((record, i) => {
          return (
            <div key={"record-" + i}>


              {(record.id === window.location.pathname.slice(1))
                ?
                <div className="wrapper">

                  <div className="panel--logo">


                    <ul className="card__categorie--list">
                      {record.categories.map((element, i) => {
                        return (
                          <li
                            className="card__categorie tooltip-bottom"
                            key={"card-categorie-" + i}

                          >
                            <img
                              src={
                         
                               "https://d2s405iubwy463.cloudfront.net/categories/icon-categorie-" + element.name
                                  .toLowerCase()
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .replace("/[^a-zA-Z0-9 ]/g", "")
                                  .replaceAll("/", "")
                                  .replaceAll(" ", "-") +  ".svg?v=3"
                              }
                              alt="logo"
                            />
                            <span>{element.name}</span>
                          </li>
                        );
                      })}
                    </ul>


                    <h2>
                      {(record.class === "sponsor" ? ("SPONSOR") : (""))}
                    </h2>



                    <div>
                      <div data-sponsor={record.class === "sponsor" ? "sponsor" : ""} className="card special">
                        <div className="card__content">
                          <img className="card__img" src={record.logoUrl} alt="logo"></img>

                        </div>
                      </div>


                      <div>
                        <p className="text__detail--short">


                          {record.companyName === "BME" || record.companyName === "OFISO" || record.companyName === "GFE" ? (
                            record.text_card_home
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>




                  </div>

                  <div className="panel--info">

                    {record.companyName === "BME" || record.companyName === "OFISO" || record.companyName === "GFE" ? (
                      <p className="panel--info__main-text detail-general-text">

                        {record.text_record_detail}


                      </p>

                    ) : (
                      <p className="detail-general-text">
                        FIVERSO destaca a los operadores del mercado de Financiación Sostenible para acercarles a sus potenciales clientes
                      </p>
                    )}

                    <div className="panel--info__wrapper">



                      <ul className="panel--info__list">
                        <li className="panel--info__item">
                          <div className="item__description">
                            Framework / Marco
                          </div>
                          <div className="item__info">
                            {/* {record.companyName === "Ethifinance/Axesor Ratings" || record.companyName === "BME" || record.companyName === "OFISO" || record.companyName === "GFE" || record.companyName === "Adif" ? (
                              <a href={record.finalPdfUrl} target="_blank" rel="noreferrer">{record.finalPdfUrl}</a>

                            ) : (
                              "..."
                            )} */}
                            {/*     {record.finalPdfUrl ? <img src={record.finalPdfUrl}></img> : ""} */}

                            <a href={record.finalPdfUrl} target="_blank" rel="noreferrer">{record.finalPdfUrl}</a>

                          </div>
                        </li>
                        
                        <li className="panel--info__item">
                          <div className="item__description">
                            Más información
                          </div>
                          <div className="item__info">
                            {/*            {record.companyName === "Ethifinance/Axesor Ratings" || record.companyName === "BME" || record.companyName === "OFISO" || record.companyName === "GFE" || record.companyName === "Adif" ? (
                             

                            ) : (
                              "..."
                            )} */}

                            <a href={record.url} target="_blank" rel="noreferrer">{record.url}</a>
                          </div>
                        </li>

                        { record.email === "..." || record.email === "" ? (
                            ""
                        ) : (
                          <li className="panel--info__item">
                            <div className="item__description">
                              Contacto
                            </div>
                            <div className="item__info">
                                <a href={`mailto:` + record.email} target="_blank" rel="noreferrer">{record.email}</a>
                            </div>
                          </li>
                        )}    

                        {(record.more_info && record.companyName === "BME")  ? (
                          <li className="panel--info__item">
                            <div className="item__description">
                              Media
                            </div>
                            <div className="item__info">

                              <iframe width="560" height="315" src={record.more_info} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>



                            </div>
                          </li>
                        ) : (
                          ""
                        )}



                      </ul>



                    </div>

                  </div>

                </div>
                : (
                  ""
                )}

            </div>

          )



        })}

      </main>






    </>
  );
}
