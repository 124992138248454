import { Link } from "react-router-dom";
import { formatHeader, formatValue } from "../utils";
import ActionTD from "./ActionTD";
import ChangeOrderTD from "./ChangeOrderTD";

export default function Table({
  format,
  name,
  pathName,
  deleteFunction,
  changeOrderFunction,
  elements,
  orderField,
  resetOrderFunction,
  resetElementOrderFunction,
}) {
  const keys = Object.keys(format || {});

  return (
    <>
    <div className="creation-head">
    <h3>{name}</h3>
      <Link to={`/${pathName}/create`}>
        <button className="btn-create">Crear</button>
      </Link>
    </div>
      

   

      <table>
        <thead>
          <tr>
            {keys.map((key, i) => (
              <th key={"th-" + i}>{formatHeader(key)}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {elements.map((element, i) => {
            return (
              <tr key={"tr-" + i}>
                {keys.map((key, j) => {
                  const value = formatValue(element, key, format);
                  if (orderField && orderField === key) {
                    return (
                      <ChangeOrderTD
                        key={"td-" + j}
                        currentValue={value}
                        changeOrderFunction={changeOrderFunction}
                        currentElement={element}
                        resetOrderFunction={resetElementOrderFunction}
                      ></ChangeOrderTD>
                    );
                  }
                  return (
                    <td key={"td-" + j}>
                      {["object", "boolean"].includes(typeof value)
                        ? JSON.stringify(value)
                        : value}
                    </td>
                  );
                })}
                <ActionTD
                  pathName={pathName}
                  element={element}
                  deleteFunction={deleteFunction}
                ></ActionTD>
              </tr>
            );
          })}
        </tbody>
      </table>

      {resetOrderFunction ? (
        <>
          <button onClick={() => resetOrderFunction()}>
            Reset general order
          </button>
        </>
      ) : (
        ""
      )}
      
    </>
  );
}
