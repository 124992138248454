import { buildParams } from "../utils";
import { fetchApi, fetchAuthApi } from "./fetchApi";

export function listNews(query) {
  return fetchApi("GET", "/last-news?" + buildParams(query));
}

export function getNewById(id) {
  return fetchAuthApi("GET", `/last-news/${id}`);
}

export function createNew(body) {
  return fetchAuthApi("POST", `/last-news`, body);
}

export function updateNew(id, body) {
  return fetchAuthApi("PUT", `/last-news/${id}`, body);
}

export function deleteNew(id) {
  return fetchAuthApi("DELETE", `/last-news/${id}`);
}

export function changeNewOrder(id, order) {
  return fetchAuthApi("PATCH", `/last-news/${id}/change-order`, { order });
}

export function resetNewsOrder() {
  return fetchAuthApi("POST", `/last-news/reset-order`);
}

export function resetNewOrderById(id) {
  return fetchAuthApi("PATCH", `/last-news/${id}/reset-order`);
}
