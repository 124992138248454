import { buildParams } from "../utils";
import { fetchApi, fetchAuthApi } from "./fetchApi";

export function listCategories(query) {
  return fetchApi("GET", "/categories?" + buildParams(query));
}

export function getCategoryById(id) {
  return fetchAuthApi("GET", `/categories/${id}`);
}

export function createCategory(body) {
  return fetchAuthApi("POST", `/categories`, body);
}

export function updateCategory(id, body) {
  return fetchAuthApi("PUT", `/categories/${id}`, body);
}

export function deleteCategory(id) {
  return fetchAuthApi("DELETE", `/categories/${id}`);
}
