import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputValidated from "../../components/InputValidated";
import { useFirstRender } from "../../hooks/useFirstRender";
import { listCategories } from "../../services/categories";
import { uploadFile } from "../../services/files";
import {
  createRecord,
  getRecordById,
  updateRecord,
} from "../../services/records";

export function RecordsForm() {
  const [record, setRecord] = useState({});
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const firstRender = useFirstRender();
  const [selectedLogo, setSelectedLogo] = useState();
  const [uploadedLogo, setUploadedLogo] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState();
  const [uploadedPdf, setUploadedPdf] = useState(false);

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      getRecordById(id).then((response) => {
        setRecord({
          ...response,
          categories: response.categories.map((c) => c.id),
        });
      });
    }
    listCategories({ limit: 10000 }).then((response) =>
      setCategories(response.elements)
    );
  }, [id]);

  const onFileChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setUploadedLogo(false);
    setSelectedPdf(e.target.files[0]);
    setUploadedPdf(false);
  };

  useEffect(() => {
    if (!firstRender && uploadedLogo) {
      sendRecordRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender, uploadedLogo]);

  useEffect(() => {
    if (!firstRender && uploadedPdf) {
      sendRecordRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender, uploadedPdf]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedLogo && !uploadedLogo) {
      const formData = new FormData();

      formData.append("file", selectedLogo);
      formData.append("folder", record.companyName);

      uploadFile(formData).then((response) => {
        if (response.error) {
          setErrors(response.message);
        } else {
          setRecord({
            ...record,
            logoKey: response.key,
          });
          setUploadedLogo(true);
        }
      });
      return;
    }
    if (selectedPdf && !uploadedPdf) {
      const formData = new FormData();

      formData.append("file", selectedPdf);
      formData.append("folder", record.companyName);

      uploadFile(formData).then((response) => {
        if (response.error) {
          setErrors(response.message);
        } else {
          setRecord({
            ...record,
            pdfKey: response.key,
          });
          setUploadedPdf(true);
        }
      });
      return;
    }
    sendRecordRequest();
  };

  const sendRecordRequest = () => {
    if (id) {
      return updateRecord(id, record)
        .then((response) => {
          if (response.error) {
            setErrors(response.message);
          } else {
            navigate("/admin/records", { replace: true });
          }
        })
        .catch((error) => console.log(error.message));
    } else {
      return createRecord(record)
        .then((response) => {
          if (response.error) {
            setErrors(response.message);
          } else {
            navigate("/admin/records", { replace: true });
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const onChange = (field) => {
    setRecord({
      ...record,
      ...field,
    });
  };

  return (
    <>
      <h1>Records form</h1>
      <Link to="/admin/records">
        <button>Go back</button>
      </Link>
      <div className="form-div">
        <form onSubmit={handleSubmit}>
          <InputValidated
            name="companyName"
            value={record.companyName || ""}
            error={errors.companyName}
            cb={onChange}
          ></InputValidated>
          <InputValidated
            name="email"
            value={record.email || ""}
            error={errors.email}
            cb={onChange}
          ></InputValidated>
          <InputValidated
            name="url"
            value={record.url || ""}
            error={errors.url}
            cb={onChange}
          ></InputValidated>
          <InputValidated
            type="multiselect"
            options={categories}
            name="categories"
            value={record.categories || ""}
            error={errors.categories}
            cb={onChange}
          ></InputValidated>
          <label htmlFor="logo">Logo:</label>
          <input type="file" name="logo" id="logo" onChange={onFileChange} />

          {record.finalLogoUrl ? (
            <p>Current File: {record.finalLogoUrl}</p>
          ) : (
            ""
          )}

          <InputValidated
            name="pdfUrl"
            value={record.pdfUrl || ""}
            error={errors.pdfUrl}
            cb={onChange}
          ></InputValidated>

          <InputValidated
            name="pdfUrl"
            value={record.pdfUrl || ""}
            error={errors.pdfUrl}
            cb={onChange}
          ></InputValidated>

          <label htmlFor="pdf">PDF:</label>
          <input type="file" name="pdf" id="pdf" onChange={onFileChange} />

          {record.finalPdfUrl ? <p>Current File: {record.finalPdfUrl}</p> : ""}

          <InputValidated
            name="class"
            value={record.class || ""}
            error={errors.class}
            cb={onChange}
          ></InputValidated>

          <InputValidated
            name="text_card_home"
            value={record.text_card_home || ""}
            error={errors.text_card_home}
            cb={onChange}
          ></InputValidated>

          <InputValidated
            name="text_record_detail"
            value={record.text_record_detail || ""}
            error={errors.text_record_detail}
            cb={onChange}
          ></InputValidated>

          <InputValidated
            name="more_info"
            value={record.more_info || ""}
            error={errors.more_info}
            cb={onChange}
          ></InputValidated>
          <button>Confirmar</button>
        </form>
      </div>
    </>
  );
}
