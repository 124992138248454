import { CategoriesList, CategoriesForm } from "./pages/categories";
import { ArticulosList, ArticulosForm } from "./pages/articulos";
import Landing from "./pages/Landing";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { RecordsList, RecordsForm, RecordsDetail } from "./pages/records";
import { Login } from "./pages/auth";
import Info from "./pages/Info";
import Sponsors from "./pages/Sponsors";
import Admin from "./pages/Admin";
import { AuthProvider } from "./components/AuthProvider";
import { NewsList, NewsForm } from "./pages/news";

export function AppRouter() {
  return (
    <BrowserRouter>
      <AuthProvider></AuthProvider>

      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/:id" element={<RecordsDetail />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/info" element={<Info />}></Route>
        <Route path="/sponsors" element={<Sponsors />}></Route>

        <Route path="/admin/categories" element={<CategoriesList />}></Route>
        <Route
          path="/admin/categories/create"
          element={<CategoriesForm />}
        ></Route>
        <Route
          path="/admin/categories/edit/:id"
          element={<CategoriesForm />}
        ></Route>

        <Route path="/admin/articulos" element={<ArticulosList />}></Route>
        <Route
          path="/admin/articulos/create"
          element={<ArticulosForm />}
        ></Route>
        <Route
          path="/admin/articulos/edit/:id"
          element={<ArticulosForm />}
        ></Route>

        <Route path="/admin/news" element={<NewsList />}></Route>
        <Route path="/admin/news/create" element={<NewsForm />}></Route>
        <Route path="/admin/news/edit/:id" element={<NewsForm />}></Route>

        <Route path="/admin/records" element={<RecordsList />}></Route>
        <Route path="/admin/records/create" element={<RecordsForm />}></Route>
        <Route path="/admin/records/edit/:id" element={<RecordsForm />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
