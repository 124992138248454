import { useState, useEffect } from "react";
import {
  deleteNew,
  listNews,
  changeNewOrder,
  resetNewsOrder,
  resetNewOrderById,
} from "../../services";
import Table from "../../components/Table";
import Loader from "../../components/Loader";

export function NewsList() {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);

  const defaultOrder = "order",
    limit = 10000;

  useEffect(() => {
    listNews({ order: defaultOrder, limit }).then((response) =>
      setNews(response.elements)
    );
  }, []);

  const deleteNewFromList = (element) => {
    return deleteNew(element.id).then((element) => {
      if (element.id) {
        setNews(news.filter((_new) => _new.id !== element.id));
      }
    });
  };

  const changeNewOrderOfList = (element, newOrder) => {
    if (element.order !== newOrder) {
      setLoading(true);
      return changeNewOrder(element.id, newOrder).then((element) => {
        if (element.id) {
          listNews({ order: defaultOrder, limit }).then((response) => {
            setNews(response.elements);
            setLoading(false);
          });
        }
      });
    }
  };

  const resetOrderOfList = () => {
    setLoading(true);
    return resetNewsOrder().then(() => {
      listNews({ order: defaultOrder, limit }).then((response) => {
        setNews(response.elements);
        setLoading(false);
      });
    });
  };

  const resetOrderOfElement = (id) => {
    setLoading(true);
    return resetNewOrderById(id).then(() => {
      listNews({ order: defaultOrder, limit }).then((response) => {
        setNews(response.elements);
        setLoading(false);
      });
    });
  };

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : (
        <Table
          format={{
            order: "",
            title: "",
            url: "",
            icon_url: "",
          }}
          name="News list"
          elements={news}
          pathName="admin/news"
          deleteFunction={deleteNewFromList}
          changeOrderFunction={changeNewOrderOfList}
          orderField={"order"}
          resetOrderFunction={resetOrderOfList}
          resetElementOrderFunction={resetOrderOfElement}
        ></Table>
      )}
    </>
  );
}
