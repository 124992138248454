import { useState, useEffect } from "react";
import {
  deleteArticulo,
  listArticulos,
  changeArticuloOrder,
  resetArticulosOrder,
  resetArticulosOrderById,
} from "../../services/articulos";
import Table from "../../components/Table";
import Loader from "../../components/Loader";

export function ArticulosList() {
  const [loading, setLoading] = useState(false);

  const [articulos, setArticulos] = useState([]);

  const defaultOrder = "order";
  const limit = 10000;

  useEffect(() => {
    listArticulos({ order: defaultOrder, limit }).then((response) =>
      setArticulos(response.elements)
    );
  }, []);

  const deleteArticuloFromList = (element) => {
    return deleteArticulo(element.id).then((element) => {
      if (element.id) {
        setArticulos(
          articulos.filter((articulo) => articulo.id !== element.id)
        );
      }
    });
  };

  const changeArticuloOrderOfList = (element, newOrder) => {
    if (element.order !== newOrder) {
      setLoading(true);
      return changeArticuloOrder(element.id, newOrder).then((element) => {
        if (element.id) {
          listArticulos({ order: defaultOrder, limit }).then((response) => {
            setArticulos(response.elements);
            setLoading(false);
          });
        }
      });
    }
  };

  const resetOrderOfList = () => {
    setLoading(true);
    return resetArticulosOrder().then(() => {
      listArticulos({ order: defaultOrder, limit }).then((response) => {
        setArticulos(response.elements);
        setLoading(false);
      });
    });
  };

  const resetOrderOfElement = (id) => {
    setLoading(true);
    return resetArticulosOrderById(id).then(() => {
      listArticulos({ order: defaultOrder, limit }).then((response) => {
        setArticulos(response.elements);
        setLoading(false);
      });
    });
  };

  return (
    <>
      {loading ? (
        <Loader></Loader>
      ) : (
        <Table
          format={{
            order: "",
            title: "",
            url: "",
            icon_url: "",
          }}
          name="Articulos list"
          elements={articulos}
          pathName="admin/articulos"
          deleteFunction={deleteArticuloFromList}
          changeOrderFunction={changeArticuloOrderOfList}
          orderField={"order"}
          resetOrderFunction={resetOrderOfList}
          resetElementOrderFunction={resetOrderOfElement}
        ></Table>
      )}
    </>
  );
}
